import React, { Component, useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import configureStore from '../store/configureStore'
import { createBrowserHistory } from 'history'
import { startListener } from '../utils/routerListener'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import "firebase/storage"
import 'firebase/functions'

import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore
import { useSelector, useDispatch } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

//import { default as Login } from './LoginApp.js'
import {env} from '../constants/Environment';
import * as UserActions from '../actions/UserActions';

const history = createBrowserHistory()
const store = configureStore(null, history)

import { FirebaseError } from '@firebase/util'

startListener(history, store)

let unsubscribe = store.subscribe(() => {
  //let previousLocation = currentLocation
  let currentLocation = store.getState().router.pathname
  /*if (previousLocation !== currentLocation) {
    // You can render your application reactively here!
  }*/
})

const fbConfig = {
  apiKey: "AIzaSyAupsuVFcvZ9CX1I6ojZIfOZAk_4mO3f_U",
  authDomain: "invite-m.firebaseapp.com",
  projectId: "invite-m",
  storageBucket: "invite-m.appspot.com",
  messagingSenderId: "637965242587",
  appId: "1:637965242587:web:df1bfa0a973a2d6782fcfe"
}

// react-redux-firebase config
const rrfConfig = {
  //userProfile: 'environments/' + env + '/users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

let fireApp = firebase.initializeApp(fbConfig)
firebase.firestore();
fireApp.functions('europe-west1');
//firebase.functions(firebaseApp);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

export default class App extends Component {
  render () {
    return (
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <div>
                    <div>
                        {React.cloneElement(this.props.children)}
                    </div>
                </div>
            </ReactReduxFirebaseProvider>
        </Provider>
    )
  }
}