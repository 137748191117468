import React, {useEffect} from 'react';
import {useFirebase} from 'react-redux-firebase'
import {useSelector, useDispatch} from 'react-redux'
import * as RouterActions from '../../actions/RouterActions'
import {StyleHelper} from '../../utils/styleHelper';
import {Button} from './';

export const Menu = function(props) {
    const dispatch = useDispatch();
    const firebase = useFirebase();
    const page = useSelector(({page}) => page.toJS());
    const active = useSelector(({page}) => page.get('selected'));

    return (
        <div className='menu' style={StyleHelper(page.config.styles.menubar, page.config.styles.variables)}>
            {
                page.config.categories.map((category, idx) => {
                    return (
                        <Button key={idx} style={{
                            default: StyleHelper(page.config.styles.menu.default, page.config.styles.variables),
                            hover: StyleHelper(page.config.styles.menu.hover, page.config.styles.variables),
                            active: StyleHelper(page.config.styles.menu.selected, page.config.styles.variables)
                        }} selected={
                            (idx === active)
                        } onClick={(e) => {
                            dispatch(RouterActions.push(page.uri + '/' + category.title))
                        }}>
                            {category.title}
                        </Button>
                    );
                })
            }
        </div>
    )
}