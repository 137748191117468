import React, {useEffect} from 'react';
import { useFirebase } from 'react-redux-firebase';
import {useSelector, useDispatch} from 'react-redux'
import * as PageActions from '../actions/PageActions'
import * as RouterActions from '../actions/RouterActions'
import {Menu, Form, List, Button} from '../components/Bringlist';
import {StyleHelper} from '../utils/styleHelper';

export default function BringlistContainer(props) {
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const page = useSelector(({page}) => page.toJS());
    const pageName = useSelector(({router}) => router.pathname.split('/')[1]);
    const urlCategory = useSelector(({router}) => (router.pathname.split('/').length < 3)?null:router.pathname.split('/')[2]);

    if(page.status === null && page.id === null){
        dispatch(PageActions.loadId(firebase, pageName));
    }

    if(page.status === 'id-loaded' && typeof page.id !== 'undefined' && page.id !== null){
        dispatch(PageActions.loadPage(firebase, page.id));
    }

    useEffect(() => {
        if(page.status === 'ready' && typeof urlCategory !== 'undefined')
            dispatch(PageActions.selectCategory(urlCategory));
    }, [urlCategory, page.status]);

    if(page.status !== 'ready' && page.status !== 'add-item'){
        return <div>Lade...</div>
    }

    return (
        <div className="bringlist" style={{
            backgroundColor: page.config.styles['background-color']
        }}>
            <Menu />

            {
                (page.selected === null)?
                    <section className="index" style={StyleHelper(page.config.styles.section, page.config.styles.variables)}>
                        <h1 style={StyleHelper(page.config.styles.title, page.config.styles.variables)}>{page.config.index.title}</h1>

                        <div className="intro" style={StyleHelper(page.config.styles.index.intro, page.config.styles.variables)}>
                            {page.config.index.intro.split(/\\n/g).map((row) => <div>{row}</div>)}
                        </div>

                        <div className="category-selection">
                        {
                            page.config.categories.map((category, idx) => {
                                return <Button style={{
                                    default: StyleHelper(page.config.styles.index['category-selection'].default, page.config.styles.variables),
                                    hover: StyleHelper(page.config.styles.index['category-selection'].hover, page.config.styles.variables)
                                }} onClick={(e) => {
                                    dispatch(RouterActions.push(page.uri + '/' + category.title));
                                }}>{category.title}</Button>
                            })
                        }
                        </div>
                    </section>
                :
                    <section style={StyleHelper(page.config.styles.section, page.config.styles.variables)}>
                        <h1 style={StyleHelper(page.config.styles.title, page.config.styles.variables)}>{page.config.categories[page.selected].title}</h1>

                        <div className="intro" style={StyleHelper(page.config.styles.intro, page.config.styles.variables)}>
                            {page.config.categories[page.selected].intro.split(/\\n/g).map((row) => <div>{row}</div>)}
                        </div>

                        <List />
                        <Form />
                    </section>
            }
        </div>
    )
}