import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'
import WebFontLoader from 'webfontloader';
import { AppContainer } from 'react-hot-loader'
import {Route} from './components/Router'

import {default as BringlistContainer} from './containers/BringlistApp';

import '../less/main.less';

WebFontLoader.load({
  google: {
    families: ['Roboto:300,400,500,700', 'Material Icons', 'Spinnaker', 'Caveat'],
  },
});

if (__DEV__) {
	console.log('Dev mode');
	if (module.hot)
		module.hot.accept()
}

/*
 * Routes:
 * 			<Route path="/image/:id/:channel/:publicationIdx" exact component={ExampleAppContainer} />	
			<Route path="/image/:id/:channel" exact component={ImageEditContainer} />	
	    	<Route path="/image/:id" exact component={ImageDetailsContainer} />	
	    	<Route path="/schedule/:id" exact component={SchedulesContainer} />	
 */

const routes = <AppContainer>
	<App>
		<div>
			<Route path="/:page" exact={true} component={BringlistContainer} />
            <Route path="/:page/:category" exact={true} component={BringlistContainer} />
		</div>
	</App>
</AppContainer>

ReactDOM.render((routes), document.getElementById( 'root' ) )