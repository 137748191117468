import * as types from '../constants/PageActionTypes'

export function loadId(firebase, uri){
	return function(dispatch){
        dispatch({
            type: types.SET_STATUS,
            status: 'load-id'
        });

        return (new Promise((resolve, reject) => {				
            (async () => {
                let firestore = firebase.firestore();

                try {
                    console.log('pages/uris/' + uri + '/config');
                    const snapshot = await firestore.collection('pages/uris/' + uri).doc('config').get();
                    dispatch({
                        type: types.SET_ID,
                        id: snapshot.data().id,
                        uri: uri
                    });
                }catch(e){
                    console.log(e);
                    dispatch({
                        type: types.SET_STATUS,
                        status: 'id-error'
                    });
                    /*if(typeof e.code === 'undefined' || e.code !== 'permission-denied')
                        throw(e);
                    else
                        dispatch({
                            type: types.NOT_ALLOWED
                        })*/
                }
                resolve();
            })();
        }));
	}
}

export function loadPage(firebase, id){
	return function(dispatch){
        dispatch({
            type: types.SET_STATUS,
            status: 'load-page'
        });

        return (new Promise((resolve, reject) => {				
				(async () => {
                    let firestore = firebase.firestore();

                    try {
                        var listener = await firestore.collection('pages/data/' + id).doc('config').onSnapshot((snapshot) => {
                            dispatch({
                                type: types.UPDATE,
                                config: snapshot.data()
                            });
                        });
                    }catch(e){
                        if(typeof e.code === 'undefined' || e.code !== 'permission-denied')
                            throw(e);
                        else
                            dispatch({
                                type: types.SET_STATUS,
                                status: 'page-error'
                            });
                    }

					resolve();
				})();
			}));
	}
}

export function selectCategory(category){
    return {
        type: types.SELECT_CATEGORY,
        categoryName: category
    }
}

export function registerListener(firebase, id, category){
	return function(dispatch){
        return (new Promise((resolve, reject) => {				
				(async () => {
                    let firestore = firebase.firestore();

                    try {
                        var listener = await firestore.collection('pages/data/' + id + '/items/' + category).onSnapshot((snapshots) => {
                            let items = {};

                            snapshots.forEach((doc) => {
                                items[doc.id] = doc.data();
                            });

                            dispatch({
                                type: types.UPDATE_ITEMS,
                                category: category,
                                items: items
                            });
                        });

                        dispatch({
                            type: types.REGISTER_LISTENER,
                            category: category,
                            listener: listener
                        });
                    }catch(e){
                        throw(e);
                    }

					resolve();
				})();
			}));
	}
}

export function add(firebase, id, category, name, item){
	return function(dispatch){
        dispatch({
            type: types.REQUEST_ADD_ITEM
        });

        return (new Promise((resolve, reject) => {				
				(async () => {
                    let firestore = firebase.firestore();

                    try {
                        await firestore.collection('events').add({
                            type: 'ADD_ITEM',
                            scope: 'bringlist',
                            data: {
                                page_id: id,
                                category: category,
                                name: name,
                                item: item
                            }
                        });
                    }catch(e){
                        throw(e);
                    }

                    dispatch({
                        type: types.DONE_ADD_ITEM
                    });    

					resolve();
				})();
			}));
	}
}

export function updateField(field, value){
    return {
        type: types.UPDATE_FIELD,
        field: field,
        value: value
    }
}