import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { useFirebase } from 'react-redux-firebase';
import * as PageActions from '../../actions/PageActions';

export const List = function(props) {
    const firebase = useFirebase();
    const dispatch = useDispatch();

    const page = useSelector(({page}) => page.toJS());
    const items = useSelector(({page}) => {
        if(!page.hasIn(['items', page.get('selected')]))
            return [];
        let it = Object.values(page.getIn(['items', page.get('selected')]).toJS());
        it.sort((a, b) => a.created.seconds - b.created.seconds);
        return it;
    });

    useEffect(() => {
        if(typeof page.listeners[page.selected] === 'undefined' || page.listeners[page.selected] === null)
            dispatch(PageActions.registerListener(firebase, page.id, page.selected));
    }, [page.selected]);

    return (
        <div className="list">
            <div>
                <h2>Wer bringt was mit?</h2>
                Hier siehst Du, was bereits mitgebracht wird.
            </div>
            {
                (items.length === 0)?
                    <div className="empty-message">{page.config.categories[page.selected].empty}</div>
                :
                <ul>
                {items.map((item, idx) => 
                    <li key={idx}>
                        <span className="name">{item.name}</span>
                        <span className="item">{item.item}</span>
                    </li>
                )}
                </ul>
            }
        </div>
    )
}