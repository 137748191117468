import React, {useState, useEffect} from 'react';
import {Button} from './Button';
import {StyleHelper} from '../../utils/styleHelper';
import {useSelector, useDispatch} from 'react-redux'
import { useFirebase } from 'react-redux-firebase';
import * as PageActions from '../../actions/PageActions';

export const Form = function(props) {
    const fields = useSelector(({page}) => page.get('fields').toJS());
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const [showSuccess, setShowSuccess] = useState(false);
    const [stateBefore, setStateBefore] = useState(null);

    const page = useSelector(({page}) => page.toJS());

    useEffect(() => {
        console.log('State Before: ', stateBefore, 'Page State: ', page.status);
        if(stateBefore === 'add-item' && page.status === 'ready' && !showSuccess){
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
                setStateBefore(null);
            }, 5000);
        }else{
            setStateBefore(page.status);
        }
    }, [page.status]);

    const handleSubmit = () => {
        if(fields.name.replace(/\s+/g, '').length === 0)return;
        if(fields.item.replace(/\s+/g, '').length === 0)return;

        dispatch(PageActions.add(firebase, page.id, page.selected, fields.name, fields.item));
    }

    if(showSuccess){
        return (
            <div className="form">
                <div className="success-message">
                    <h2>Vielen Dank!</h2>
                    Dein Eintrag wurde hinzugefügt.
                </div>
            </div>
        )
    }

    return (
        <div className="form">
            <div>
                <h2>Möchtest du auch etwas mitbringen?</h2>
                Falls du auch etwas mitbringen möchtest, kannst du dich hier eintragen:
            </div>
            <form onSubmit={(e) => {
                handleSubmit();
                e.preventDefault();
                e.stopPropagation();
                return false;
            }}>
                <div>
                    <label>Dein Name:</label>
                    <input placeholder="Gib hier bitte deinen Namen ein."  value={fields.name} onChange={(e) => dispatch(PageActions.updateField('name', e.target.value))}/>
                </div>
                <div>
                    <label>Was möchtest du mitbringen?</label>
                    <input placeholder="Trage hier bitte ein, was du gerne mitbringen möchtest." value={fields.item} onChange={(e) => dispatch(PageActions.updateField('item', e.target.value))}/>
                </div>

                <Button style={{
                    default: StyleHelper(page.config.styles.add.default, page.config.styles.variables),
                    hover: StyleHelper(page.config.styles.add.hover, page.config.styles.variables),
                    disabled: StyleHelper(page.config.styles.add.disabled, page.config.styles.variables)
                }} disabled={fields.name.length === 0 || fields.item.length === 0 || page.status !== 'ready'} onClick={(e) => handleSubmit()}>Eintrag hinzufügen</Button>
            </form>
        </div>
    )
}