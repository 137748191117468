import React, {useState} from 'react';

export const Button = function(props) {
    const [isHover, setHover] = useState(false);

    return (
        <a {...props} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} style={props.disabled?props.style.disabled:props.selected?props.style.active:isHover?props.style.hover:props.style.default}>
            {props.children}
        </a>
    )
}