import Immutable from 'immutable'
import * as types from '../constants/PageActionTypes'

const initialState = Immutable.Map({
    loading: true,
    status: null,
    id: null,
    selected: null,
    fields: Immutable.Map({
        name: '',
        item: ''
    }),
    listeners: Immutable.Map(),
    items: Immutable.Map()
})

function updatePage(state, config){
    state = setStatus(state, 'ready');
    state = state.set('config', Immutable.fromJS(config));
    return state;
}

function setId(state, id, uri){
    state = setStatus(state, 'id-loaded');
    state = state.set('uri', uri);
    return state.set('id', id);
}

function setStatus(state, status){
    return state.set('status', status);
}

function updateField(state, field, value){
    return state.setIn(['fields', field], value);
}

function requestAddItem(state){
    return setStatus(state, 'add-item');
}

function doneAddItem(state){
    state = updateField(state, 'name', '');
    state = updateField(state, 'item', '');
    return setStatus(state, 'ready');
}

function registerListener(state, category, listener){
    state = state.setIn(['listeners', category], listener);
    return state;
}

function updateItems(state, category, updatedItems){
    let items = Immutable.Map();
    if(state.hasIn(['items', category])){
        items = state.getIn(['items', category]);
    }

    for(var itemId in updatedItems)
        items = items.set(itemId, Immutable.fromJS(updatedItems[itemId]));
    
    state = state.setIn(['items', category], items);
    return state;
}

function selectCategory(state, categoryName){
    if(categoryName === null)
        return state.set('selected', null);

    let categories = state.getIn(['config', 'categories']).toJS();
    for(var idx in categories){
        let category = categories[idx];

        if(category.title.toLowerCase() === categoryName.toLowerCase()){
            state = state.set('selected', parseInt(idx));
            return state;
        }
    }

    return state;
}

export default function calendar (state = initialState, action) {
  switch (action.type) {
    case types.SET_STATUS:
        return setStatus(state, action.status);
    case types.SET_ID:
        return setId(state, action.id, action.uri);
    case types.UPDATE:
        return updatePage(state, action.config);
    case types.UPDATE_FIELD:
        return updateField(state, action.field, action.value);
    case types.REQUEST_ADD_ITEM:
        return requestAddItem(state);
    case types.DONE_ADD_ITEM:
        return doneAddItem(state);
    case types.REGISTER_LISTENER:
        return registerListener(state, action.category, action.listener);
    case types.UPDATE_ITEMS:
        return updateItems(state, action.category, action.items);
    case types.SELECT_CATEGORY:
        return selectCategory(state, action.categoryName);
    default:
      return state
  }
}